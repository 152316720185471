import React from 'react'
import styles from './Error.module.scss'
import { Flex } from '@edwin-edu/ui/server'

export const renderErrorType = (children: string) => {
  return <Flex className={styles.errorType}>{children}</Flex>
}
export const renderErrorMessage = (children: string) => {
  return <Flex className={styles.errorMessage}>{children}</Flex>
}
export const renderErrorActionMessage = (children: React.JSX.Element) => {
  return <Flex className={styles.errorActionMessage}>{children}</Flex>
}

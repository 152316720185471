'use client'

import { useRouter } from 'next/navigation'
import { useTranslations } from 'next-intl'

import { Button } from '@edwin-edu/ui/server'

import ErrorPage from '@/components/Error/ErrorPage'
import {
  renderErrorActionMessage,
  renderErrorMessage,
  renderErrorType,
} from '@/components/Error/SharedComponents'
import ErrorIllustrationBlueIcon from '@/components/Icons/Error/ErrorIllustrationBlueIcon'

const ErrorBoundary = () => {
  const t = useTranslations('error')
  const c = useTranslations('common')
  const router = useRouter()
  const refreshPage = () => {
    router.refresh()
    router.push('/')
  }

  return (
    <ErrorPage pageIconSVG={<ErrorIllustrationBlueIcon />}>
      {renderErrorType(t('error300'))}
      {renderErrorMessage(t('applicationError'))}
      {renderErrorActionMessage(
        <p>
          {t('somethingWrong')}
        </p>,
      )}
      <Button style={{ width: 'fit-content' }} onClick={refreshPage} data-test="error-boundary-refresh-button">
        {c('goBack')}
      </Button>
    </ErrorPage>
  )
}

export default ErrorBoundary

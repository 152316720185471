import React from 'react'
import styles from './Error.module.scss'
import { Flex } from '@edwin-edu/ui/server'

interface ErrorPageProps {
  pageIconSVG?: JSX.Element
  children?: JSX.Element[],
}

const ErrorPage = ({ pageIconSVG, children }: ErrorPageProps) => {
  return (
    <Flex>
      <Flex className={styles.errorContainer}>
        <Flex className={styles.leftContainer}>{pageIconSVG}</Flex>
        <Flex className={styles.verticalDivider} />
        <Flex direction="column" className={styles.rightContainer}>
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ErrorPage